import React from 'react';
import Feature from '../../components/feature/Feature';
import './features.css';

const featuresData = [
  {
    title: 'Give us your vision and we will bring it to life.',
    text: 'Our team will take the concept that you are envisioning and we will design it and will not provide anything less than 100% .',
  },
  {
    title: 'Keep it Simple and Affordable.',
    text: 'Instead of paying incredible amounts of money to get something to promote your business or image let our consultants help give you the most affordable solution.',
  },
  {
    title: 'We are always Avaialable and Ready to assist you.',
    text: 'Our consultants and team are always ready to listen to you and are constantly keeping in touch with you.',
  },
  {
    title: 'Let us help promote your business or services.',
    text: 'Our primary focus and goal is to give you promotional material that will put your business on the map.',
  },
];

const Features = () => (
  <div className="gpt3__features section__padding" id="features">
    <div className="gpt3__features-heading">
      <h1 className="gradient__text">The Future is Now and You Just Need to Realize It. Step into the Future Today & Let's Make it Happen:</h1>
      <h1 className="gradient__text">sales@newteq.web.za.</h1>
    </div>
    <div className="gpt3__features-container">
      {featuresData.map((item, index) => (
        <Feature title={item.title} text={item.text} key={item.title + index} />
      ))}
    </div>
  </div>
);
export default Features