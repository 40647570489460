import React from 'react';
import Feature from '../../components/feature_nft/Feature';
import './nft.css';

const Nft = () => (
  <div className="gpt3__whatgpt3 section__margin" id="nft">
  <div className="gpt3__whatgpt3-feature">
    <Feature title="What You Know About NFT?" text="Let's talk business... I mean NFTs'" />
  </div>
  <div className="gpt3__whatgpt3-heading">
    <h1 className="gradient__text">The Era of NFT's</h1>
  </div>
  <div className="gpt3__whatgpt3-container">
    <Feature title="Create your Vision" text="We will create the NFT you have envisoned so you can focus on starting your NFT business." />
    <Feature title="Improve your NFTs'" text="Let us help make those small changes to your existing NFTs' if you are a little busy." />
  </div>
</div>
);

export default Nft