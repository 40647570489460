import React from 'react';
import {Article} from '../../components';
import { blog01, blog02, blog03, blog04, blog05, blog06, blog07, blog08, blog09 } from './imports';
import './blog.css';

const Blog = () => {
  return (
  <div className="gpt3__blog section__padding" id="blog">
    <div className="gpt3__blog-heading">
      <h1 className="gradient__text">Come take a peek <br /> We have cookies.</h1>
    </div>
    <div className="gpt3__blog-container">
      <div className="gpt3__blog-container_groupA">
        <Article imgUrl={blog01} title="Gallery" flink="Here's a taste of some of the projects and applications NEWTEQ have provided." />
      </div>
      <div className="gpt3__blog-container_groupB">
        <Article imgUrl={blog02} title="Costing application" />
        <Article imgUrl={blog03} title="Stock take application" />
        <Article imgUrl={blog04} title="Barcode scanning app" />
        <Article imgUrl={blog05} title="Websites" />
        <Article imgUrl={blog06} title="NFT" />
        <Article imgUrl={blog07} title="Animated advertising" />
        <Article imgUrl={blog08} title="Analytical Dashboards" />
        <Article imgUrl={blog09} title="Animation" />
      </div>
    </div>
  </div>
)
};

export default Blog