import React from 'react';
import { google, visual, atlassian, dropbox, shopify } from './import';
import './brand.css';

const Brand = () => {
    return (
        <div className='gpt3__brand section__padding'>
            <div>
      <img src={google} />
    </div>
    <div>
      <img src={visual} />
    </div>
    <div>
      <img src={atlassian} />
    </div>
    <div>
      <img src={dropbox} />
    </div>
    <div>
      <img src={shopify} />
    </div>
        </div>
    )
}

export default Brand
