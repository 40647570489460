import React from 'react';
import people from '../../assets/people.png';
import ai from '../../assets/logo 2.png';
import './header.css';


const Header = () => (
  <div className="gpt3__header section__padding" id="home">
    <div className="gpt3__header-content">
      <h1 className="gradient__text">Let&apos;s Build Something Amazing.</h1>
      <p>sales@newteq.web.za</p>
      

      <div className="gpt3__header-content__people">
        <img src={people} />
        <p>600 people visited in the last 24 hours</p>
      </div>
    </div>

    <div className="gpt3__header-image">
      <img src={ai} />
    </div>
  </div>
);

export default Header;