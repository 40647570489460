import React from 'react';
import './cta.css';

const CTA = () => (
  <div className="gpt3__cta">
    <div className="gpt3__cta-content">
      <p>Request an Appointment to Get Started</p>
      <h3>Email:</h3>
      <h3>sales@newteq.web.za</h3>
    </div>
    <div className="gpt3__cta-btn">
    </div>
  </div>
);

export default CTA
