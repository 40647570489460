import React from 'react';
import { asp, android, angular, postman, c } from './import2';
import './tools.css';

const Tools = () => {
    return (
        <div className='gpt3__brand section__padding'>
            <div>
      <img src={asp} />
    </div>
    <div>
      <img src={android} />
    </div>
    <div>
      <img src={angular} />
    </div>
    <div>
      <img src={postman} />
    </div>
    <div>
      <img src={c} />
    </div>
        </div>
    )
}

export default Tools
