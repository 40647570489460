import React from 'react';
import gpt3Logo from '../../assets/logo 1.png';
import './footer.css';

const Footer = () => (
  <div className="gpt3__footer section__padding">
    <div className="gpt3__footer-heading">
      <h1 className="gradient__text">Do you want to step into the future before others?</h1>
    </div>

   

    <div className="gpt3__footer-links">
      <div className="gpt3__footer-links_logo">
        <img src={gpt3Logo} alt="gpt3_logo" />
        <p>Newteq (PTY) Ltd, <br /> All Rights Reserved</p>
      </div>
      <div className="gpt3__footer-links_div">
        <h4>Links</h4>
        <p>Instagram</p>
      </div>
      <div className="gpt3__footer-links_div">
        <h4>Company</h4>
        <p>Terms & Conditions </p>
        <p>Privacy Policy</p>
      </div>
      <div className="gpt3__footer-links_div">
        <h4>Get in touch</h4>
        <p> Johannesburg, South Africa</p>
        <p>Ashlin: 072 770 8322</p>
        <p>sales@newteq.web.za</p>
      </div>
    </div>

    <div className="gpt3__footer-copyright">
      <p>@2022 Newteq (PTY) Ltd. All rights reserved.</p>
    </div>
  </div>
);

export default Footer