
import React from 'react';
import Feature from '../../components/feature/Feature';
import './whatGPT3.css';

const WhatGPT3 = () => (
  <div className="gpt3__whatgpt3 section__margin" id="wgpt3">
    <div className="gpt3__whatgpt3-feature">
      <Feature title="Who are NEWTEQ?" text="We offer services such as 2D and 3D models, animated clips, Logos and develop applications using C#, ASP.Net, VB.Net, Angular, API's, Android and Java" />
      
      
    </div>
    <div className="gpt3__whatgpt3-heading">
      <h1 className="gradient__text">The possibilities are beyond your imagination</h1>
      
      
    </div>
    <div className="gpt3__whatgpt3-container">
      <Feature title="Animated Models" text="NEWTEQ have a team of animators who can create videos and images that merge animation with the clients imagintation" />
      <Feature title="Reactive Websites" text="Websites no longer need hundreds of links and pages upon pages of information that users won't actually. To have a truly eye-popping website that gives viewers all the necessary information about your business NEWTEQ follow the standard 'Less Is More!' " />
      <Feature title="Clips and Marketing" text="Marketing is no longer commercials on television. NEWTEQ strive to give clients innovative ways of promoting their business using short video clips that can be used on Facebook, Instagram or any social media platform of their choice." />
      <Feature title="Application Development" text="NEWTEQ provide the service of developing client specific application or improvinf existing applicatons." />
    </div>
  </div>
);

export default WhatGPT3